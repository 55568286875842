// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, {useState} from "react";
import {Card, Row, Col, Button, Modal, Spinner} from "react-bootstrap";
import {API} from "aws-amplify";
import { Auth } from 'aws-amplify';


import { Amplify } from 'aws-amplify';

// import amplifyconfig from './amplifyconfiguration.json';
// import {list} from 'aws-amplify/storage';
import { Storage } from 'aws-amplify';

// Amplify.configure(amplifyconfig);

function S3Request(props) {
  const [json, setJson] = useState(null);
  const [show, setShow] = useState(false);

  async function handleSubmit() {
    const credentials = await Auth.currentUserCredentials();
    console.log("identityId", credentials.identityId);
    setShow(true);
    const response = await getMyPhotos();
    setJson(response);
  }

  function handleClose() {
    setJson(null);
    setShow(!show);
  }
   
  function getMyPhotos() {
  Storage.list('photos/', { level: 'private' })
  .then(
    ({ results }) => console.log(results))
  .catch((err) => console.log(err));

  }



// async function onChange(e) {
//   const file = e.target.files[0];
//   try {
//     await Storage.put('photos/'+file.name, 'Private Content', {
//       level: 'private',
//       contentType: 'image/png'
//     });
//   } catch (error) {
//     console.log("Error uploading file: ", error);
//   }
// }


async function onChange(e) {
  const file = e.target.files[0];
  try {
    await Storage.put('photos/'+file.name, file, {
      contentType: "image/png", // contentType is optional
      level: 'private',
    });
  } catch (error) {
    console.log("Error uploading file: ", error);
  }
}



  return (
    <>
      <Row>
        <Col sm={3}></Col>
        <Col sm={6}>
          <Card style={{width: "100%"}}>
            <Card.Body>
              <Card.Title>
                <h3 style={{textAlign: "center"}}>Hello </h3>
              </Card.Title>
              <Row>
                <Col sm={2}></Col>
                <Col sm={8}>
              
                <input type="file" onChange={onChange} />;
                
                  {" "}
                  <Button
                    variant="outline-success"
                    onClick={handleSubmit}
                    block
                  >
                    Get My S3 Objects
                  </Button>
                </Col>
                <Col sm={2}> </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={3}></Col>
      </Row>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h3 style={{textAlign: "center"}}>Response</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {json ? (
            <p>Here is the response: {json.message}</p>
          ) : (
            <h3 style={{textAlign: "center"}}>
              <Spinner animation="border" variant="primary" />
            </h3>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default S3Request;
