// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, {useEffect, useState, Fragment} from "react";
import Amplify, {Auth, Hub} from "aws-amplify";
import {Container} from "react-bootstrap";

import Navigation from "./components/Navigation.js";
import FederatedSignIn from "./components/FederatedSignIn.js";
import MainRequest from "./components/MainRequest.js";
import S3Request from "./components/S3Request.js";

import "./App.css";



Amplify.configure({
  Auth: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_yEZSw7G8p",
    userPoolWebClientId: "1rs5ojibc0psaumish6nn9na5p",
    identityPoolId: "eu-west-1:86c10ef5-f1f8-41b3-9049-8f9f3875242a",
    oauth: {
      domain: "rp-azure-2023.auth.eu-west-1.amazoncognito.com",
      scope: ["email", "openid"],
            // scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn: "https://azure-cognito-demo.rprenty.com",
      redirectSignOut: "https://azure-cognito-demo.rprenty.com",
      responseType: "code"
    }
  },
    Storage: {
    AWSS3: {
      bucket: 'rp-azure-cognito-storage144325-dev', //REQUIRED -  Amazon S3 bucket name
      region: 'eu-west-1', //OPTIONAL -  Amazon service region
    }
  },
  API: {
    endpoints: [
      {
        name: "MyBlogPostAPI",
        endpoint: "https://ronanprenty.com"
      }
    ]
  }
});

// import amplifyconfig from './amplifyconfiguration.json';
// Amplify.configure(amplifyconfig);


const federatedIdName =
  "AZURE-COGNITO-2023";

function App() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    Hub.listen("auth", ({payload: {event, data}}) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          setToken("grating...");
          getToken().then(userToken => setToken(userToken.idToken.jwtToken));
          break;
        case "signOut":
          setToken(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });
  }, []);

  function getToken() {
    return Auth.currentSession()
      .then(session => session)
      .catch(err => console.log(err));
  }

  return (
    <Fragment>
      <Navigation token={token} />
      <Container fluid>
        <br />
        {token ? (
          <S3Request token={token} />
        ) : (
          <FederatedSignIn federatedIdName={federatedIdName} />
        )}
      </Container>
    </Fragment>
  );
}

export default App;
